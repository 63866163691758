import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home'

const root = ReactDOM.createRoot(document.getElementById('root') as ReactDOM.Container);
root.render(
  <React.StrictMode>
    <section>
        <Home />
    </section>
  </React.StrictMode>
)
